import React from "react";
import { useCompany, useMap } from "../../hooks/companyHooks";
import { isOdd } from "../../utils/functions/generalFunctions";
import PopUpBlockDisplay from "./PopUpBlockDisplay";
import ScoreSelector from "./ScoreSelector";
import VideoPlayer from "./VideoPlayer";

const FieldPopUp = ({ fieldId, catId, mapId, companyId }) => {
  const [selectedId, setSelectedId] = React.useState(fieldId);
  const company = useCompany(companyId);
  const map = useMap(company, mapId);
  const category = map.categories.find((c) => c._id === catId);
  const { fields } = category;
  const current = fields.map((f) => f._id).indexOf(selectedId);

  const field = fields.find((f) => f._id === selectedId);
  const [video, setVideo] = React.useState(null);

  const nextField = () => {
    if (current === fields.length - 1) return;
    setSelectedId(fields[current + 1]._id);
  };

  const prevField = () => {
    if (current === 0) return;
    setSelectedId(fields[current - 1]._id);
  };

  return (
    <div style={{ minWidth: "85vw" }} className="text-left">
      <div className="container">
        <h3 className="mb-4">
          {category.name} | <strong>{field.name}</strong>{" "}
          <ScoreSelector
            field={field}
            catId={catId}
            map={map}
            company={company}
          />
        </h3>
        {field.summary && <p>{field.summary}</p>}
        {video ? (
          <VideoPlayer onClose={() => setVideo(null)} videoId={video} />
        ) : (
          <>
            {field.videoUrl && (
              <h4 className="btn-link" onClick={() => setVideo(field.videoUrl)}>
                Video Explanation
              </h4>
            )}
            <div className="d-flex justify-content-between flex-wrap">
              {field.blocks.map((block, index) => (
                <div
                  key={block._id}
                  className="px-0 my-3 border border-1"
                  style={{
                    width:
                      index === field.blocks.length - 1 && !isOdd(index)
                        ? "100%"
                        : "48%",
                  }}
                >
                  <PopUpBlockDisplay block={block} setVideo={setVideo} />
                </div>
              ))}
            </div>
          </>
        )}
        {current !== 0 && (
          <button className="btn btn-sm" onClick={prevField}>
            Previous
          </button>
        )}
        {current < fields.length - 1 && (
          <button className="btn btn-sm float-right" onClick={nextField}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default FieldPopUp;
