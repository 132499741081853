import React from "react";
import NavListItem from "./NavListItem";

const AdminLinks = () => {
  const admin = [
    { label: "Salespeople", url: "/admin" },
    {
      label: "Maps",
      url: "/admin/maps",
    },
    {
      label: "All Users",
      url: "/admin/users",
    },
  ];

  return (
    <>
      {admin.map((link, index) => (
        <NavListItem link={link} key={index} />
      ))}
    </>
  );
};

export default AdminLinks;
