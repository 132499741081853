import React from "react";
import FieldError from "./FieldError";

/* 
Creates drop down list based on accepted array.
*/

const Select = ({
  name,
  label,
  options,
  optionGroups,
  error,
  valid,
  lang,
  append,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="input-group">
        <select
          name={name}
          id={name}
          className={`custom-select ${error && "is-invalid"} ${
            valid && "is-valid"
          }`}
          {...rest}
        >
          <option value="">{`${label}...`}</option>

          {optionGroups
            ? options.map((group, index) => (
                <optgroup label={group.label} key={index}>
                  {group.options.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </optgroup>
              ))
            : options.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
        </select>
        {append}

        <FieldError name={name} error={error} />
      </div>
    </div>
  );
};

export default Select;
