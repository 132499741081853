import { useMutation, useQueryCache } from "react-query";
import { handleException } from "../utils/functions/generalFunctions";

export const useGeneralMutation = (fn, extraQueryArr = []) => {
  const cache = useQueryCache();

  const [mutate] = useMutation(fn, {
    onError: (err) => {
      console.log(err.response ? err.response : err);
      throw new Error(handleException(err));
    },
    onSettled: () => {
      extraQueryArr.forEach((query) => cache.invalidateQueries(query));
    },
  });

  return mutate;
};
