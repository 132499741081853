import React from "react";
import LoginForm from "./LoginForm";
import { useDispatch } from "../../../context/StoreProvider";
import { storeUpdater } from "../../../context/StoreProvider";
import SignUp from "../signUp/SignUp";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import { getCurrentUser } from "../../../services/authService";
import { Redirect } from "react-router-dom";
import { useCompany } from "../../../hooks/companyHooks";

const Login = () => {
  const dispatch = useDispatch();
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");
  const user = getCurrentUser();
  const company = useCompany(user ? user.company._id : null);

  if (company)
    return <Redirect to={`/map/${company._id}/${company.maps[0]._id}`} />;

  return (
    <div>
      <div className="pt-2 px-5 mx-auto" style={{ maxWidth: "600px" }}>
        <h2 className="h4">Welcome Back!</h2>
        <p className="text-muted">Login</p>
        <LoginForm />
        <button
          className="btn btn-link text-muted btn-sm"
          onClick={() => openModal(<ForgotPassword />)}
        >
          Forgot Password?
        </button>
        <hr />
        <p>
          Don't have an account?{" "}
          <span
            className="text-primary btn-link"
            onClick={() => openModal(<SignUp />)}
            style={{ cursor: "pointer" }}
          >
            Sign Up
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
