import React from "react";

// columns: array
// sortColumn: object
// onSort: function

const TableHeader = ({ columns, sortColumn, onSort = () => {}, sort }) => {
  const renderSortIcon = (column) => {
    if (column.path !== sortColumn.path) return null;

    return sortColumn.order ? (
      <i className="fa fa-sort-asc" />
    ) : (
      <i className="fa fa-sort-desc" />
    );
  };

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            className={sort ? "clickable" : ""}
            key={column.path || column.key}
            onClick={
              sort ? () => onSort(setSortColumn(column)(sortColumn)) : () => {}
            }
          >
            {column.label} {sort && renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

const setSortColumn = (column) => (sortColumn) => {
  return {
    path: column.path,
    order: column.path === sortColumn.path ? !sortColumn.order : true,
  };
};
