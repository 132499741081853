import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
const R = require("ramda");

const Table = ({
  columns,
  data,
  sort = false,
  initialSort = "name",
  initialDirection = true,
}) => {
  const [sortColumn, setSortColumn] = React.useState({
    path: initialSort,
    order: initialDirection,
  });

  const sorted = R.sortBy(R.prop(sortColumn.path))(data);

  return (
    <div className="table-responsive">
      <table className="table">
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={setSortColumn}
          sort={sort}
        />
        <TableBody
          columns={columns}
          data={sortColumn.order ? sorted : sorted.reverse()}
          sort={sort}
        />
      </table>
    </div>
  );
};

export default Table;
