//dependencies
import React from "react";
import { ToastContainer } from "react-toastify";

import Main from "./Main";
import Footer from "./components/footer";

//css
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import ModalContent from "./components/modal/ModalContent";

const App = () => {
  //get and update user in store
  return (
    <>
      <ToastContainer />
      <Header />
      <Main />
      <Footer />
      <ModalContent />
    </>
  );
};

export default App;
