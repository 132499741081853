import React from "react";

import ForgotPasswordForm from "./ForgotPasswordForm";
import { useDispatch } from "../../../context/StoreProvider";
import { storeUpdater } from "../../../context/StoreProvider";
import Login from "../login/Login";

const EmailSender = ({ setEmail }) => {
  const dispatch = useDispatch();
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");

  return (
    <div className="pt-2 px-5">
      <h2 className="h4">Recover Password</h2>
      <p className="text-muted">
        Enter your email and a recovery code will be sent to you.
      </p>
      <ForgotPasswordForm setEmail={setEmail} />

      <p className="my-2 text-muted">
        Remember your password?
        <span
          className="text-primary btn-link"
          onClick={() => openModal(<Login />)}
          style={{ cursor: "pointer" }}
        >
          Login
        </span>
      </p>
    </div>
  );
};

export default EmailSender;
