import React from "react";
import { calcCatScore, calcColor } from "../../utils/functions/mapFunctions";
import CatScore from "./CatScore";
import FieldDisplay from "../mapTemplateManagement/FieldDisplay";

const FieldChart = ({ cats, top, edit, locked, mapId }) => {
  return (
    <div className="row">
      {cats.map((cat, index) => (
        <div key={index} className="col">
          {!top && (
            <CatScore
              name={cat.name}
              score={calcCatScore(cat)}
              color={calcColor(calcCatScore(cat))}
            />
          )}

          <div className="list-group">
            <div
              className={`list-group-item py-1 mt-1 text-center`}
              style={{ height: "100px" }}
            >
              {cat.description}
            </div>
            {cat.fields.map((field, index) => (
              <FieldDisplay
                fieldId={field._id}
                key={index}
                cat={cat}
                edit={edit}
                locked={locked}
                index={index}
                mapId={mapId}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FieldChart;
