import uniqueString from "unique-string";

const R = require("ramda");

export const sortDate = (key) => (items) =>
  [...items].sort(
    (a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()
  );

export const listSort = (sortPath) => (list) => (order = "asc") => {
  const sorted = R.sortBy(R.prop(sortPath))(list);
  return order === "asc" ? sorted : sorted.reverse();
};

export const filterUnique = (arr) => {
  return arr.reduce(isNotIncluded, []);
};

export const findFirst = (param) => (arr) => (searchTerm) =>
  arr.find((item) => item[param] === searchTerm);

export const isNotIncluded = (arr, v) => (!arr.includes(v) ? [...arr, v] : arr);

export const includesSubArr = (arr) => (subArr) =>
  subArr.reduce(
    (acc, next) => (acc === false ? false : arr.includes(next)),
    true
  );

export const includesAny = (arr) => (subArr) =>
  subArr.some((item) => arr.includes(item));

export const stringSearch = (item) => (value) => {
  return item.toLowerCase().includes(value.toLowerCase());
};

export const getId = (item) => R.prop("_id", item);

export const addProp = (prop) => (obj) => ({ ...obj, prop });

export const keyCombiner = (whatToCombine = []) => (obj) => {
  return whatToCombine
    .reduce((str, next) => str.concat(obj[next], " "), "")
    .trim();
};

export const searchIn = (items) => (searchValue) => (obj) =>
  R.or(...items.map((item) => stringSearch(obj[item])(searchValue)));

export const strNumToBool = (str) => Boolean(parseInt(str));

export const boolToNumStr = (bool) => (bool ? "1" : "0");

export const spreadArgs = (fn) => (argsArr) => fn(...argsArr);

export const assignId = (item) => ({ ...item, _id: uniqueString() });

export const updateSingleItem = (updates) => (itemToUpdate) => (testItem) =>
  testItem._id === itemToUpdate._id ? { ...testItem, ...updates } : testItem;

export const removeItems = (arr) => (key) => (criteria) =>
  arr.filter((itemInArry) => itemInArry[key] !== criteria);

export const isBetween = (num1) => (num2) => (item) =>
  R.gte(item, num1) && R.lte(item, num2);

export const sample = (list) => list[randomInt(list.length - 1)];

export const randomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const isOdd = (num) => R.modulo(R.__, 2)(num);
export const isEven = (num) => R.not(isOdd(num));

export const removeEmpty = (e) => e != null;

export const handleException = (ex) =>
  ex.response ? ex.response.data : ex.message;
