import React, { useState } from "react";
import EmailSender from "./EmailSender";
import PasswordCodeForm from "./PasswordCodeForm";

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);

  return !email ? (
    <EmailSender setEmail={setEmail} />
  ) : (
    <PasswordCodeForm email={email} />
  );
};

export default ForgotPassword;
