import React from "react";
import { withRouter } from "react-router";
import { useCompany, useMap } from "../../hooks/companyHooks";
import MapDisplay from "../mapTemplateManagement/MapDisplay";

const MapPage = ({ match }) => {
  const company = useCompany(match.params.companyId);
  const map = useMap(company, match.params.mapId);
  return (
    <div className="mx-5 mb-5">
      <MapDisplay map={map} company={company} />
    </div>
  );
};

export default withRouter(MapPage);
