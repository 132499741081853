import React from "react";
import { useUser } from "../../hooks/userHooks";
import { Link } from "react-router-dom";
import { logout } from "../../services/authService";

const MemberTopBarLinks = () => {
  const user = useUser("");
  const doLogout = () => {
    logout();
    window.location = "/";
  };
  if (!user) return null;
  return (
    <div>
      <div
        className="text-muted dropdown-toggle"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className={`fa fa-user`} />
      </div>
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <Link className="dropdown-item" to="/account-settings">
          Account Settings
        </Link>
        {/* <div className="dropdown-divider"></div> */}
        <div className="dropdown-item" onClick={doLogout}>
          Log Out
        </div>
      </div>
    </div>
  );
};

export default MemberTopBarLinks;
