import React from "react";
import { strToLocal } from "../../utils/functions/dateFunctions";

const MapDropdown = ({ maps, map, handleChange }) => {
  return (
    <div className="form-group col">
      <select
        className="form-control"
        onChange={handleChange}
        defaultValue={map._id}
      >
        {maps.map((m) => (
          <option key={m._id} value={m._id} id={m._id}>
            {m.name} | {strToLocal(m.creationDate)("en")}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MapDropdown;
