import http from "./httpService";

const apiEndpoint = "/maps";

export async function createMap(map) {
  const { data } = await http.post(apiEndpoint, map);
  return data;
}

export const getMap = async (_, mapId) => {
  const { data } = await http.get(`${apiEndpoint}/${mapId}`);
  return data;
};

export async function getMaps() {
  const { data } = await http.get(`${apiEndpoint}/`);
  return data;
}

export async function updateMap({ mapId, updates }) {
  const { data } = await http.put(`${apiEndpoint}/${mapId}`, {
    updates,
  });
  return data;
}

export async function deleteMap(mapId) {
  const { data } = await http.delete(`${apiEndpoint}/${mapId}`);
  return data;
}
