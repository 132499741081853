import http from "./httpService";

const apiEndpoint = "/email";

export async function sendPasswordReset({email, code}) {
  const {data} = http.post(`${apiEndpoint}/forgotpassword`, {
    email,
    code,
  });
  return data
}

export async function sendEmailVerification({email, code}) {
  const {data} = await http.post(`${apiEndpoint}/verify-email`, {
    email,
    code,
  });
  return data
}

export async function sendContactForm({ email, message, subject, to }) {
  const {data} = await http.post(`${apiEndpoint}/contactform`, {
    email,
    message,
    subject,
    to,
  });
  return data
}
