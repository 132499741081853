import React from "react";

const ExternalLink = ({ title, href }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
};

export default ExternalLink;
