import React, { createContext, useReducer } from "react";
import { useContext } from "react";
import initialState from "./initialState";
import reducer from "./reducer";

export const Store = createContext();
export const dispatchContext = createContext();

export const storeUpdater = (dispatch) => (type) => (payload) =>
  dispatch({ type, payload });

const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  return (
    <dispatchContext.Provider value={dispatch}>
      <Store.Provider value={store}>{children}</Store.Provider>
    </dispatchContext.Provider>
  );
};

export const useStore = () => useContext(Store);
export const useDispatch = () => useContext(dispatchContext);

export default StoreProvider;
