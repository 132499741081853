import React from "react";
import UpdateUserInfo from "./UpdateUserInfo";

const AccountSettings = () => {
  return (
    <div className="container my-2 text-center">
      <h1 className="h3 mb-3">Account Settings</h1>
      <div className="row justify-content-center my-5">
        <div className="col-xs-11 col-md-6">
          <UpdateUserInfo />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
