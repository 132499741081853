import React, { useState } from "react";
import Joi from "joi-browser";
import Flash, { useFlash } from "../common/Flash";
import useForm from "../common/hooksForm/useForm";
import { getMaps } from "../../services/mapService";

import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useQuery } from "react-query";
import { useUpdateCompany } from "../../hooks/companyHooks";
import Loader from "../common/Loader";
const R = require("ramda");

const NewCompanyMapForm = ({ company }) => {
  const { data: maps } = useQuery(["maps"], getMaps);
  const dispatch = useDispatch();
  const closeModal = storeUpdater(dispatch)("CLOSE_MODAL");
  const [initialValues] = useState({
    // startingTemplate: maps[0]._id,
  });
  const [flash, setFlash] = useFlash();

  const doUpdateCompany = useUpdateCompany(company._id, ["company"]);

  const createCompanyMap = async (values) => {
    const newMap = createNewMap(
      maps.find((m) => m._id === values.startingTemplate)
    )(values.name);
    try {
      await doUpdateCompany({
        maps: [newMap, ...company.maps],
      });
      closeModal();
    } catch (ex) {
      setFlash(ex.message, "danger");
      return true;
    }
  };

  const form = useForm(createCompanyMap, schema, initialValues);

  if (!maps) return <Loader />;

  return (
    <div className="container">
      <h2>New Deal</h2>
      <form className="needs-validation" onSubmit={form.handleSubmit}>
        {form.renderInput({ name: "name", label: "Deal Description" })}
        <Flash flash={flash} />
        {form.renderSelect({
          name: "startingTemplate",
          label: "Starting Template",
          options: R.sort(dateSort)(maps),
        })}
        {form.renderButton({ name: "create", label: "Create Map" })}
      </form>
    </div>
  );
};

export default NewCompanyMapForm;

const createNewMap = (template) => (name) => ({
  name: name,
  categories: [...template.categories].map((cat) => ({
    ...cat,
    fields: cat.fields.map((field) => ({ ...field, score: 0 })),
  })),
  creationDate: new Date(),
  locked: false,
});

const schema = {
  name: Joi.string().required(),
  startingTemplate: Joi.string().required(),
};

const dateSort = (a, b) =>
  new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
