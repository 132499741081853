import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap";
import "./scss/main.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.css";
import "react-virtualized/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { ReactQueryDevtools } from "react-query-devtools";

//Context
import StoreProvider from "./context/StoreProvider";
import { PusherProvider } from "./context/PusherProvider";
import Pusher from "pusher-js";

import {
  QueryCache,
  ReactQueryCacheProvider,
  ReactQueryConfigProvider,
} from "react-query";

const queryCache = new QueryCache();

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
});

ReactDOM.render(
  <ReactQueryCacheProvider queryCache={queryCache}>
    <ReactQueryConfigProvider config={{ queries: { retry: 0 } }}>
      <PusherProvider pusher={pusherClient}>
        <StoreProvider>
          <BrowserRouter>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BrowserRouter>
        </StoreProvider>
      </PusherProvider>

      {/* Dev tools should not show on live */}
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryConfigProvider>
  </ReactQueryCacheProvider>,
  document.getElementById("root")
);
