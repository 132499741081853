const reducer = (state, action) => {
  switch (action.type) {
    // MODAL ACTIONS
    case "CLOSE_MODAL":
      return { ...state, modal: { ...state.modal, open: false } };
    case "OPEN_MODAL":
      return { ...state, modal: { open: true, content: action.payload } };

    default:
      return state;
  }
};

export default reducer;
