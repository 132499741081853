import React, { useState } from "react";
import Joi from "joi-browser";
import Flash, { useFlash } from "../common/Flash";
import useForm from "../common/hooksForm/useForm";
import { createMap, getMaps } from "../../services/mapService";

import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useQuery } from "react-query";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";

const NewMapTemplateForm = () => {
  const { data: maps } = useQuery(["maps"], getMaps);
  const dispatch = useDispatch();
  const closeModal = storeUpdater(dispatch)("CLOSE_MODAL");
  const [initialValues] = useState({
    startingTemplate: maps[0]._id,
  });
  const [flash, setFlash] = useFlash();

  const doCreateMap = useGeneralMutation(createMap, ["maps"]);

  const createGlobalMap = async (values) => {
    const cleanCategories = sanitizeTemplate(
      maps.find((m) => m._id === values.startingTemplate)
    );

    try {
      await doCreateMap({
        name: values.name,
        categories: cleanCategories,
      });
      closeModal();
    } catch (ex) {
      setFlash(ex.message, "danger");
      return true;
    }
  };

  const form = useForm(createGlobalMap, schema, initialValues);

  return (
    <div className="container">
      <h2>New Deal</h2>
      <form className="needs-validation" onSubmit={form.handleSubmit}>
        {form.renderInput({ name: "name", label: "Deal Description" })}

        {form.renderSelect({
          name: "startingTemplate",
          label: "Starting Template",
          options: maps,
        })}
        <Flash flash={flash} />
        {form.renderButton({ name: "create", label: "Create Map" })}
      </form>
    </div>
  );
};

export default NewMapTemplateForm;

const sanitizeTemplate = (template) => {
  return template.categories.map((cat) => {
    const { name, fields } = cat;
    const cleanFields = fields.map((field) => {
      const { _id, ...rest } = field;
      const cleanBlocks = field.blocks.map((block) => {
        const { _id, ...restOfBlock } = block;
        return restOfBlock;
      });
      return { ...rest, blocks: cleanBlocks };
    });
    return { name, fields: cleanFields };
  });
};

const schema = {
  name: Joi.string().required(),
  startingTemplate: Joi.string().required(),
};
