import React from "react";

const MarkdownCheatSheet = () => {
  return (
    <div className="container my-3">
      <h4>Markdown Cheat Sheet</h4>
      <br />
      <p># This is a Heading</p>
      <h1>This is a Heading</h1>
      <br />
      <p>## This is a Heading 2</p>
      <h2>This is a Heading 2</h2>
      <p>### This is a Heading 3</p>
      <h3>This is a Heading 3</h3>
      <p>Lists:</p>
      <p>- Item 1</p>
      <p>- Item 2</p>
      <p>- Item 3</p>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
      <p>1. Item 1</p>
      <p>2. Item 2</p>
      <p> 3. Item 3</p>

      <ol>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ol>
      <p>
        External Link:{" "}
        {`<a href='www.google.com' title='This is an external link' />`}
      </p>
      <p>
        Video Link: {`<Video id={vimeoVideoId} label='This is link text' />`}
      </p>
    </div>
  );
};

export default MarkdownCheatSheet;
