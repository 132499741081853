import React, { useState } from "react";

const Flash = ({ flash }) => {
  const { message, type } = flash;
  if (!message) return <div />;
  return (
    <div
      className={`alert alert-${type} alert-dismissible fade show`}
      role="alert"
    >
      {message}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Flash;

export const useFlash = (initialFlash) => {
  const [flash, setFlash] = useState(
    initialFlash ? initialFlash : { message: "", type: "" }
  );
  const flashSetter = flashMessage(setFlash);

  return [flash, flashSetter];
};

const flashMessage = (fn) => (message, type = "success") =>
  fn({
    type,
    message,
  });
