import React from "react";

const Close = ({ handleClick }) => {
  return (
    <div className="d-flex align-items-center pt-1 pr-1 ">
      <button onClick={handleClick} className="ml-auto active close p-2">
        <i className="fa fa-close" />
      </button>
    </div>
  );
};

export default Close;
