import React from "react";
import { withRouter } from "react-router";
import { useUser } from "../../hooks/userHooks";
import { includesAny } from "../../utils/functions/generalFunctions";
import LinkButtons from "./LinkButtons";
import MapDropdown from "../mapTemplateManagement/MapDropdown";

const CompanyHeader = ({ history, company, map }) => {
  const user = useUser("permissions");

  const handleChange = (e) => {
    e.preventDefault();
    history.push(`/map/${company._id}/${e.target.value}`);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col">
          <h4>
            <strong>Pivotal</strong>Conversations
          </h4>
          <h5>Deal Bulletproofing</h5>
        </div>

        <h3 className="col">{company.name}</h3>
        <MapDropdown
          maps={company.maps}
          map={map}
          handleChange={handleChange}
        />
        {user && includesAny(["admin", "editor"])(user.permissions) && (
          <LinkButtons map={map} companyId={company._id} />
        )}
      </div>

      <hr className="bg-warning" />
    </div>
  );
};

export default withRouter(CompanyHeader);
