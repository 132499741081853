import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = ({ error, name, ...rest }) => {
  return (
    <div className="text-center my-3">
      <ReCAPTCHA
        {...rest}
        className="d-inline-block"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
    </div>
  );
};

export default Recaptcha;
