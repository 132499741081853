import React from "react";
import { useUser } from "../../hooks/userHooks";
import Loader from "../common/Loader";

const LinkDisplay = ({ viewer, editor }) => {
  const user = useUser("permissions");

  if (!user) return <Loader />;

  return (
    <div style={{ minWidth: "300px" }}>
      {(user.permissions.includes("editor") ||
        user.permissions.includes("admin")) && (
        <div className="form-group">
          <label htmlFor="viewerLink">Read Only Link</label>
          <input
            type="text"
            className="form-control"
            id="viewerLink"
            value={viewer}
            readOnly={true}
          />
        </div>
      )}
      {user.permissions.includes("admin") && (
        <div className="form-group">
          <label htmlFor="editorLink">Edit Link</label>
          <input
            type="text"
            className="form-control"
            id="editorLink"
            value={editor}
            readOnly={true}
          />
        </div>
      )}
    </div>
  );
};

export default LinkDisplay;
