import React from "react";
import useForm from "../common/hooksForm/useForm";
import Joi from "joi-browser";
import { useUser } from "../../hooks/userHooks";
import Flash, { useFlash } from "../common/Flash";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
import { updateUser } from "../../services/userService";

const UpdateUserInfo = () => {
  const user = useUser("nameFirst nameLast email");
  const [flash, setFlash] = useFlash();
  const [initialValues, setInitialValues] = React.useState({});

  React.useEffect(() => {
    if (!user || !user.nameFirst) return;
    setInitialValues({
      nameFirst: user.nameFirst,
      nameLast: user.nameLast,
      email: user.email,
    });
    return;
  }, [user]);

  const [error, setError] = React.useState("");

  const doUpdateUser = useGeneralMutation(updateUser);

  const handleSubmit = async (values) => {
    if (values.newPassword && !passwordsMatch(values)) {
      setError("Passwords did not match.", "danger");
      return true;
    }
    const { confirmPassword, newPassword, ...rest } = values;
    try {
      await doUpdateUser({
        userId: user._id,
        updates: {
          ...rest,
          ...(newPassword ? { password: newPassword } : {}),
        },
      });

      setFlash("Profile Updated");
    } catch (ex) {
      setError(ex.message, "danger");
    }
    return true;
  };

  const form = useForm(handleSubmit, schema, initialValues);
  return (
    <form className="needs-validation" onSubmit={form.handleSubmit}>
      <Flash flash={flash} />
      {form.renderInput({ name: "nameFirst", label: "First Name" })}
      {form.renderInput({ name: "nameLast", label: "Last Name" })}
      {form.renderInput({ name: "email", label: "Email" })}
      {form.renderInput({
        name: "newPassword",
        label: "New Password",
        type: "password",
      })}
      {form.renderInput({
        name: "confirmPassword",
        label: "Confirm New Password",
        type: "password",
      })}
      {/* {form.renderRecaptcha({ name: "recaptcha" })} */}
      {form.renderError(error)}
      {form.renderButton({
        name: "updateProfile",
        label: "Update Profile",
        className: "btn-block",
      })}
    </form>
  );
};

export default UpdateUserInfo;

const schema = {
  nameFirst: Joi.string().required(),
  nameLast: Joi.string().required(),
  email: Joi.string().required().email(),
  newPassword: Joi.string()
    .min(8)
    .allow("")
    .allow(null)
    .error(() => ({ message: "Password must be at least 8 characters long" })),
  confirmPassword: Joi.string()
    .min(8)
    .allow("")
    .allow(null)
    .error(() => ({ message: "Password must be at least 8 characters long" })),
};

const passwordsMatch = ({ newPassword, confirmPassword }) =>
  newPassword === confirmPassword;
