import React from "react";
import Markdown from "markdown-to-jsx";
import VideoLink from "./VideoLink";
import ExternalLink from "./ExternalLink";

const PopUpBlockDisplay = ({ block, setVideo }) => {
  return (
    <>
      <h4
        style={{ backgroundColor: block.color, color: "#FFF" }}
        className="pl-2 py-2"
      >
        {block.name}
      </h4>

      <div className="p-2">
        <Markdown
          options={{
            overrides: {
              Video: {
                component: VideoLink(setVideo),
              },
              a: ExternalLink,
            },
          }}
        >
          {block.content}
        </Markdown>
      </div>
    </>
  );
};

export default PopUpBlockDisplay;
