//Dependencies
import React, { useState } from "react";
import Joi from "joi-browser";

//my pieces
import { loginWithCode } from "../../../services/authService";
import useForm from "../../common/hooksForm/useForm";
import { useGeneralMutation } from "../../../hooks/useGeneralMutation";

const PasswordCodeForm = ({ email }) => {
  const [initialValues] = useState({});
  const [error, setError] = useState("");

  const doLoginWithCode = useGeneralMutation(loginWithCode);

  const handleSubmit = async (values) => {
    try {
      await doLoginWithCode({ email, code: values.code });
      window.location = "/account-settings"; //pulls where the user was redirected from if they were
    } catch (ex) {
      setError(ex.response.data);
      return true;
    }
  };

  const form = useForm(handleSubmit, schema, initialValues);

  return (
    <div className="pt-2 px-5">
      <h3>Password Reset Code</h3>
      <p>Please enter the 4-digit code sent to your email.</p>
      <form className="needs-validation" onSubmit={form.handleSubmit}>
        {form.renderInput({ name: "code", label: "Password Reset Code" })}
        {form.renderError(error)}
        {form.renderButton({
          name: "submit",
          label: "Recover Password",
          className: "btn-block",
        })}
      </form>
    </div>
  );
};

export default PasswordCodeForm;

const schema = {
  code: Joi.string().required().length(4),
};
