import Joi from "joi-browser";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { withRouter } from "react-router";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
import { getMap, updateMap } from "../../services/mapService";
import useForm from "../common/hooksForm/useForm";
import MarkdownCheatSheet from "./MarkdownCheatSheet";

const FieldForm = ({ field, mapId, catId }) => {
  const { data: map } = useQuery(["map", mapId], getMap, {
    enabled: mapId,
  });
  const dispatch = useDispatch();
  const closeModal = storeUpdater(dispatch)("CLOSE_MODAL");
  const [initialValues] = useState(initialValuesReducer(field));

  const doUpdateMap = useGeneralMutation(updateMap, ["map"]);

  const handleSubmit = async (values) => {
    const updatedCategories = newCategories(map)(catId)({
      ...field,
      ...valuesReducer(values),
    });

    try {
      await doUpdateMap({
        mapId,
        updates: {
          categories: updatedCategories,
        },
      });
      closeModal();
    } catch (ex) {
      console.log(ex.message);
    }
    return true;
  };

  const form = useForm(handleSubmit, schema, initialValues);
  return (
    <div style={{ minWidth: "90vw" }} className="text-left">
      <div className="container">
        <h3>Field Form</h3>
        <form className="needs-validation" onSubmit={form.handleSubmit}>
          {form.renderInput({
            name: "name",
            label: "Field Name",
            showLabel: true,
          })}
          {form.renderInput({
            name: "summary",
            label: "Field Summary",
            showLabel: true,
          })}
          {form.renderInput({
            name: "scoringWeight",
            label: "Scoring Weight",
            showLabel: true,
          })}
          {form.renderInput({
            name: "videoUrl",
            label: "Category Vimeo ID",
            showLabel: true,
          })}
          {form.renderRadio({
            name: "isActionStep",
            label: "Action Step Block",
            options: blockOptions,
          })}
          <div className="row">
            <div className="col-6">
              <h4>Block 1</h4>
              {form.renderInput({
                name: "block1Title",
                label: "Block 1 Title",
                showLabel: true,
              })}
              {form.renderInput({
                name: "block1Color",
                label: (
                  <div>
                    Block 1 Color{" "}
                    <span
                      style={{
                        backgroundColor: form.values.block1Color,
                        cursor: "default",
                      }}
                      className={`btn btn-circle`}
                    />
                  </div>
                ),
                showLabel: true,
              })}
              {form.renderTextarea({
                name: "block1Content",
                label: "Block 1 Content",
                showLabel: true,
                rows: 8,
              })}
            </div>
            <div className="col-6">
              <h4>Block 2</h4>

              {form.renderInput({
                name: "block2Title",
                label: "Block 2 Title",
                showLabel: true,
              })}
              {form.renderInput({
                name: "block2Color",
                label: (
                  <div>
                    Block 2 Color{" "}
                    <span
                      style={{
                        backgroundColor: form.values.block2Color,
                        cursor: "default",
                      }}
                      className={`btn btn-circle`}
                    />
                  </div>
                ),
                showLabel: true,
              })}
              {form.renderTextarea({
                name: "block2Content",
                label: "Block 2 Content",
                showLabel: true,
                rows: 8,
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h4>Block 3</h4>

              {form.renderInput({
                name: "block3Title",
                label: "Block 3 Title",
                showLabel: true,
              })}
              {form.renderInput({
                name: "block3Color",
                label: (
                  <div>
                    Block 3 Color{" "}
                    <span
                      style={{
                        backgroundColor: form.values.block3Color,
                        cursor: "default",
                      }}
                      className={`btn btn-circle`}
                    />
                  </div>
                ),
                showLabel: true,
              })}
              {form.renderTextarea({
                name: "block3Content",
                label: "Block 3 Content",
                showLabel: true,
                rows: 8,
              })}
            </div>
            <div className="col-6">
              <h4>Block 4</h4>

              {form.renderInput({
                name: "block4Title",
                label: "Block 4 Title",
                showLabel: true,
              })}
              {form.renderInput({
                name: "block4Color",
                label: (
                  <div>
                    Block 4 Color{" "}
                    <span
                      style={{
                        backgroundColor: form.values.block4Color,
                        cursor: "default",
                      }}
                      className={`btn btn-circle`}
                    />
                  </div>
                ),
                showLabel: true,
              })}
              {form.renderTextarea({
                name: "block4Content",
                label: "Block 4 Content",
                showLabel: true,
                rows: 8,
              })}
            </div>
            <div className="col-6">
              <h4>Block 5</h4>

              {form.renderInput({
                name: "block5Title",
                label: "Block 5 Title",
                showLabel: true,
              })}
              {form.renderInput({
                name: "block5Color",
                label: (
                  <div>
                    Block 5 Color{" "}
                    <span
                      style={{
                        backgroundColor: form.values.block5Color,
                        cursor: "default",
                      }}
                      className={`btn btn-circle`}
                    />
                  </div>
                ),
                showLabel: true,
              })}
              {form.renderTextarea({
                name: "block5Content",
                label: "Block 5 Content",
                showLabel: true,
                rows: 8,
              })}
            </div>
          </div>
          {form.renderButton({ label: "Save" })}
        </form>
      </div>
      <MarkdownCheatSheet />
    </div>
  );
};

export default withRouter(FieldForm);

const schema = {
  name: Joi.string().required(),
  summary: Joi.string().allow("").allow(null),
  scoringWeight: Joi.number().required(),
  videoUrl: Joi.string().allow("").allow(null),
  isActionStep: Joi.number(),
  block1Title: Joi.string().allow("").allow(null),
  block1Color: Joi.string().allow("").allow(null),
  block1Content: Joi.string().allow("").allow(null),
  block2Title: Joi.string().allow("").allow(null),
  block2Color: Joi.string().allow("").allow(null),
  block2Content: Joi.string().allow("").allow(null),
  block3Title: Joi.string().allow("").allow(null),
  block3Color: Joi.string().allow("").allow(null),
  block3Content: Joi.string().allow("").allow(null),
  block4Title: Joi.string().allow("").allow(null),
  block4Color: Joi.string().allow("").allow(null),
  block4Content: Joi.string().allow("").allow(null),
  block5Title: Joi.string().allow("").allow(null),
  block5Color: Joi.string().allow("").allow(null),
  block5Content: Joi.string().allow("").allow(null),
};

const initialValuesReducer = (field) => {
  return {
    name: field.name,
    summary: field.summary,
    scoringWeight: field.scoringWeight,
    videoUrl: field.videoUrl ? field.videoUrl : "",
    isActionStep:
      field.blocks.length &&
      field.blocks.map((block) => block.isActionStep).indexOf(true) >= 0
        ? field.blocks.map((block) => block.isActionStep).indexOf(true)
        : 4,
    block1Title: "You are Clear to “Green” when...",
    block1Color: "#117A65",
    block1Content: "",
    block2Title: "Stakeholder Tips",
    block2Color: "#2471A3",
    block2Content: "",
    block3Title: "Inquiry / Discovery Questions",
    block3Color: "#D4AC0D",
    block3Content: "",
    block4Title: "Reality Check Questions",
    block4Color: "#7D3C98",
    block4Content: "",
    block5Title: "Action Items",
    block5Color: "#C0392B",
    block5Content: "",
    ...field.blocks.reduce((acc, next, index) => {
      return {
        ...acc,
        [`block${index + 1}Title`]: next.name,
        [`block${index + 1}Color`]: next.color,
        [`block${index + 1}Content`]: next.content,
      };
    }, {}),
  };
};

const valuesReducer = (values) => {
  const { name, summary, scoringWeight, videoUrl, isActionStep } = values;
  const blocks = ["1", "2", "3", "4", "5"];

  return {
    name,
    summary,
    scoringWeight,
    videoUrl,
    blocks: blocks.reduce((acc, next, index) => {
      return [
        ...acc,
        {
          name: values["block" + next + "Title"],
          content: values["block" + next + "Content"],
          isActionStep: index === parseInt(isActionStep),
          color: values["block" + next + "Color"],
        },
      ];
    }, []),
  };
};

const newCategories = (map) => (catId) => (field) => {
  return map.categories.map((c) =>
    c._id === catId
      ? { ...c, fields: c.fields.map((f) => (f._id === field._id ? field : f)) }
      : c
  );
};

const blockOptions = [
  { _id: 0, name: "Block 1" },
  { _id: 1, name: "Block 2" },
  { _id: 2, name: "Block 3" },
  { _id: 3, name: "Block 4" },
  { _id: 4, name: "Block 5" },
];
