import React, { useState } from "react";
import { generateToken } from "../../services/companyService";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useCompany } from "../../hooks/companyHooks";
import LinkDisplay from "../companyManagement/LinkDisplay";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
var TinyURL = require("tinyurl");

const LinkButtons = ({ map, companyId }) => {
  const dispatch = useDispatch();
  const company = useCompany(companyId);
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");
  const [processing, setProcessing] = useState(false);

  const doGenerateToken = useGeneralMutation(generateToken);

  const showLinks = async (map) => {
    setProcessing(true);
    try {
      const viewer = await doGenerateToken({
        mapId: map._id,
        permissions: ["user"],
        companyId: company._id,
      });
      const editor = await doGenerateToken({
        mapId: map._id,
        permissions: ["editor"],
        companyId: company._id,
      });
      const viewerLink = await defineLink(viewer);
      const editorLink = await defineLink(editor);
      openModal(<LinkDisplay viewer={viewerLink} editor={editorLink} />);
      setProcessing(false);
    } catch (ex) {
      console.log(ex.response);
      setProcessing(false);
    }
  };

  return (
    <div className="col-1">
      <div
        className="row"
        style={{ cursor: "pointer" }}
        onClick={!processing ? () => showLinks(map) : () => {}}
      >
        <button className="btn btn-sm btn-dark">Share</button>
      </div>
    </div>
  );
};

export default LinkButtons;

const defineLink = async (token) => {
  try {
    return await TinyURL.shorten(
      `${process.env.REACT_APP_ROOT_URL}/login/${token}`
    );
  } catch (err) {
    console.log(err);
  }
};
