import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";
import { includesSubArr } from "../../utils/functions/generalFunctions";
import Loader from "./Loader";

//verifies login.
const ProtectedRoute = ({
  path,
  component: Component,
  requiredRole,
  render,
  subRequired,
  history,
  needActive = true,
  ...rest
}) => {
  let sessionUser = getCurrentUser();
  if (!sessionUser) return <Loader />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!sessionUser)
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location, requireLogin: true },
              }}
            />
          );
        if (!authorized(requiredRole, sessionUser))
          return <Redirect to={"/not-found"} />;

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default withRouter(ProtectedRoute);

const authorized = (requiredRole, user) => {
  return requiredRole && !includesSubArr(user.permissions)(requiredRole)
    ? false
    : true;
};
