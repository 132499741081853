import React from "react";
import ReactPlayer from "react-player";
import Close from "../modal/Close";

const VideoPlayer = ({ videoId, onClose }) => {
  return (
    <div className="w-75 mx-auto">
      <Close handleClick={onClose} />
      <ReactPlayer
        config={{
          vimeo: {
            playerOptions: {
              autoplay: true,
              controls: true,
            },
          },
        }}
        style={{ marginLeft: "auto", marginRight: "auto", width: "100%" }}
        url={`https://vimeo.com/${videoId}`}
      />
    </div>
  );
};

export default VideoPlayer;
