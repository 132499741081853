import React from "react";

const Search = ({ onChange, value }) => {
  return (
    <input
      name="search"
      id="search"
      placeholder="Search"
      value={value}
      className={`form-control`}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
};

export default Search;
