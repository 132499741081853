import { updateUser } from "../../services/userService";

//const R = require("ramda");

export const getNewExpiration = (userInfo) => (customer) => {
  if (!userInfo.stripeId || userInfo.stripeSubId === "blank")
    return userInfo.expirationDate;

  const sub = [...customer.subscriptions.data][0];
  return sub
    ? new Date(sub.current_period_end * 1000).toString()
    : userInfo.expirationDate;
};

export const dbUpdater = (userId) => async (updates) =>
  await updateUser(userId, updates);

export const activeTutorial = (user) => (page) =>
  user.tutorials && !user.tutorials[page] ? false : true;

export const isAdmin = (user) => user.permissions.includes("admin");
