import React from "react";
import FieldChart from "../mapTemplateManagement/FieldChart";
import TotalScore from "../companyMapManagement/TotalScore";
import { calcColor, calcTotalScore } from "../../utils/functions/mapFunctions";
import { Pusher } from "../../context/PusherProvider";
import CompanyHeader from "../companyMapManagement/CompanyHeader";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import { useQueryCache } from "react-query";
import { Link } from "react-router-dom";

const MapDisplay = ({ edit = false, map, company }) => {
  const cache = useQueryCache();

  const updateMap = async () => {
    try {
      await cache.refetchQueries(["company"]);
    } catch (ex) {
      console.log(ex);
    }
  };

  if (!map) return <Loader />;

  return (
    <div className=" my-3">
      {!edit && company && (
        <>
          <Pusher
            channel="valueProp"
            event={company._id}
            onUpdate={() => updateMap()}
          />
          <CompanyHeader company={company} map={map} />
          <Link
            className="btn-link h5"
            to={`/critical-path/${company._id}/${map._id}`}
          >
            View Critical Path
          </Link>
        </>
      )}
      <FieldChart
        cats={map.categories.slice(0, 5)}
        top={false}
        edit={edit}
        locked={map.locked}
        mapId={map._id}
      />
      <TotalScore
        color={calcColor(calcTotalScore(map))}
        score={calcTotalScore(map)}
      />
    </div>
  );
};

export default withRouter(MapDisplay);
