//Dependencies
import React, { useState } from "react";
import Joi from "joi-browser";
//my pieces
import useForm from "../../common/hooksForm/useForm";
import { register } from "../../../services/userService";
import { useGeneralMutation } from "../../../hooks/useGeneralMutation";
import { loginWithJwt } from "../../../services/authService";
// import { sendEmailVerification } from "../../../services/emailService";

const SignUpForm = () => {
  const [initialValues] = useState({});
  const [error, setError] = useState("");

  const schema = {
    nameFirst: Joi.string().required(),
    nameLast: Joi.string().required(),
    email: Joi.string().required().email(),
    password: Joi.string().required().min(8),
    // recaptcha: Joi.string().required(),
  };

  const doRegister = useGeneralMutation(register);

  const handleSubmit = async (values) => {
    try {
      const response = await doRegister(values);
      // await sendEmailVerification(response.data.email, response.data.code);
      loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (ex) {
      setError(ex.message);
      return true;
    }
  };

  const form = useForm(handleSubmit, schema, initialValues);

  return (
    <form className="needs-validation" onSubmit={form.handleSubmit}>
      {form.renderInput({ name: "nameFirst", label: "First Name" })}
      {form.renderInput({ name: "nameLast", label: "Last Name" })}
      {form.renderInput({ name: "email", label: "Email" })}
      {form.renderInput({
        name: "password",
        label: "Password",
        type: "password",
      })}
      {/* {form.renderRecaptcha({ name: "recaptcha" })} */}
      {form.renderError(error)}
      {form.renderButton({
        name: "signUp",
        label: "Sign Up",
        className: "btn-block",
      })}
    </form>
  );
};

export default SignUpForm;
