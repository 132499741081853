import React from "react";
import { withRouter } from "react-router";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useCompany } from "../../hooks/companyHooks";
import Loader from "../common/Loader";
import NewCompanyMapForm from "./NewCompanyMapForm";
import MapTable from "./MapTable";
const R = require("ramda");

const CompanySummary = ({ match }) => {
  const dispatch = useDispatch();
  const company = useCompany(match.params.companyId);

  const addMap = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(
      <NewCompanyMapForm company={company} />
    );

  if (!company) return <Loader />;

  return (
    <div className="container">
      <h2>Company Profile</h2>
      <h3>{company.name}</h3>
      <h4>{company.primaryContact && company.primaryContact.name}</h4>
      <h5>{company.primaryContact && company.primaryContact.email}</h5>
      <button className="btn btn-primary my-2" onClick={addMap}>
        Create New Deal
      </button>
      {!R.isEmpty(company.maps) && (
        <MapTable maps={company.maps} companyId={company._id} />
      )}
    </div>
  );
};

export default withRouter(CompanySummary);
