import React from "react";
import { useUpdateCompany } from "../../hooks/companyHooks";
import { useUser } from "../../hooks/userHooks";
import { includesAny } from "../../utils/functions/generalFunctions";
import { color } from "../mapTemplateManagement/FieldDisplay";

const ScoreSelector = ({ field, catId, map, company }) => {
  const user = useUser("permissions");
  const doUpdateCompany = useUpdateCompany(company._id, ["company"]);

  const updateScore = async (score) => {
    const newCats = updateCategories(map.categories)(catId)(field)(score);
    try {
      await doUpdateCompany({
        maps: company.maps.map((m) =>
          m._id === map._id ? { ...m, categories: newCats } : m
        ),
      });
    } catch (ex) {
      console.log(ex.response);
    }
  };

  if (!map) return null;

  return (
    <ul className="navbar-nav float-right ml-2" style={{ zIndex: 1000 }}>
      <li className="nav-item dropdown">
        <button
          type="button"
          className={`btn btn-${color(field)} btn-circle mt-1`}
          id={`${field._id}DropDown`}
          data-toggle={
            !map.locked &&
            user &&
            includesAny(["admin", "editor"])(user.permissions)
              ? "dropdown"
              : null
          }
          disabled={
            !user || !includesAny(["admin", "editor"])(user.permissions)
          }
        />
        <div className="dropdown-menu py-0" style={{ color: "#FFF" }}>
          <p
            className="dropdown-item bg-danger my-0"
            style={{ color: "#FFF", cursor: "pointer" }}
            onClick={() => updateScore(-1 * field.scoringWeight)}
          >
            Constraint
          </p>
          <p
            className="dropdown-item bg-warning my-0"
            style={{ color: "#FFF", cursor: "pointer" }}
            onClick={() => updateScore(0)}
          >
            Neutral
          </p>
          <p
            className="dropdown-item bg-success my-0"
            style={{ color: "#FFF", cursor: "pointer" }}
            onClick={() => updateScore(1 * field.scoringWeight)}
          >
            Contributor
          </p>
        </div>
      </li>
    </ul>
  );
};

export default ScoreSelector;

const updateCategories = (categories) => (catId) => (field) => (score) => {
  return categories.map((cat) =>
    cat._id === catId
      ? {
          ...cat,
          fields: cat.fields.map((f) =>
            f._id === field._id ? { ...f, score } : f
          ),
        }
      : cat
  );
};
