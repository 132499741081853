import React from "react";
import { useQuery } from "react-query";
import { getCurrentUser } from "../services/authService";
import { getAllUsers, getUserInfo } from "../services/userService";

export const useUser = (requestedData) => {
  const user = getCurrentUser();

  const { data } = useQuery(
    ["user", user ? user._id : null, requestedData],
    getUserInfo,
    { enabled: user && !user.unregistered }
  );

  return data ? data : user;
};

export const useAllUsers = () => {
  const [filtered, setFiltered] = React.useState([]);
  const { data } = useQuery(["allUsers"], getAllUsers);

  React.useEffect(() => {
    if (!data) return;

    setFiltered(data.users);
  }, [data]);

  return [filtered, setFiltered, data ? data.users : []];
};
