import http from "./httpService";

const apiEndpoint = "/companies";

export async function createCompany(company) {
  const { data } = await http.post(apiEndpoint, company);
  return data;
}

export const getCompany = async (_, companyId) => {
  const { data } = await http.get(`${apiEndpoint}/${companyId}`);
  return data;
};

export async function getCompanies() {
  const { data } = await http.get(`${apiEndpoint}/`);
  return data;
}

export async function updateCompany({ companyId, updates }) {
  const { data } = await http.put(`${apiEndpoint}/${companyId}`, {
    updates,
  });
  return data;
}

export async function generateToken({ mapId, permissions, companyId }) {
  const { data } = await http.post(`${apiEndpoint}/generate-token`, {
    map: mapId,
    permissions,
    _id: companyId,
  });
  return data;
}

export async function deleteCompany(companyId) {
  const { data } = await http.delete(`${apiEndpoint}/${companyId}`);
  return data;
}
