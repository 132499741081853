//Dependencies
import React, { useState } from "react";
import Joi from "joi-browser";
import { withRouter } from "react-router-dom";
//my pieces
import useForm from "../../common/hooksForm/useForm";
import { login } from "../../../services/authService";
import { useGeneralMutation } from "../../../hooks/useGeneralMutation";

const LoginForm = ({ location }) => {
  const [initialValues] = useState({});
  const [error, setError] = useState("");

  const schema = {
    email: Joi.string().required().email(),
    password: Joi.string().required(),
  };
  //

  const doLogin = useGeneralMutation(login);

  const handleSubmit = async (values) => {
    try {
      await doLogin({ email: values.email, password: values.password });
      const { state: history } = location; //this comes from ProtectedRoute component
      window.location = history ? history.from.pathname : "/"; //pulls where the user was redirected from if they were
    } catch (ex) {
      setError(ex.message);
      return true;
    }
  };

  const form = useForm(handleSubmit, schema, initialValues);

  return (
    <form className="needs-validation" onSubmit={form.handleSubmit}>
      {form.renderInput({ name: "email", label: "Email" })}
      {form.renderInput({
        name: "password",
        label: "Password",
        type: "password",
      })}
      {form.renderError(error)}
      {form.renderButton({
        name: "login",
        label: "Log In",
        className: "btn-block",
      })}
    </form>
  );
};

export default withRouter(LoginForm);
