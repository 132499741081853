import React from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { getCurrentUser } from "../../services/authService";
// import PublicLinks from "./PublicLinks";
// import MemberLinks from "./MemberLinks";
import AdminLinks from "./AdminLinks";

const Navbar = () => {
  const user = getCurrentUser();
  return (
    <nav className="navbar navbar-expand-md navbar-light">
      <Link className="navbar-brand" to="/">
        <img src={Logo} alt="Value Prop Logo" height="60px" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {user && user.permissions.includes("admin") && <AdminLinks />}
          {/* {user && <MemberLinks />} */}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
