import React from "react";
import Modal from "./Modal";
import { useStore } from "../../context/StoreProvider";

const ModalContent = () => {
  const { modal } = useStore();
  if (!modal) return null;
  return (
    <aside>
      <Modal>
        <div className="mb-4">{modal.content}</div>
      </Modal>
    </aside>
  );
};

export default ModalContent;
