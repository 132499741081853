import React from "react";

const VideoLink = (setVideo) => ({ id, label }) => {
  return (
    <span
      className="btn-link"
      style={{ cursor: "pointer" }}
      onClick={() => setVideo(id)}
    >
      {label}
    </span>
  );
};

export default VideoLink;
