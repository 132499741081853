import React from "react";
import { useEffect } from "react";

const PusherContext = React.createContext();

const PusherProvider = ({ pusher, children }) => {
  return (
    <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
  );
};

// Create custom hook for using the Pusher Context
// Fail fast if not within a PusherProvider (thx Kent C. Dodds)
function usePusher() {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  const { pusher } = context;
  return pusher;
}

const Pusher = ({ channel, event, onUpdate }) => {
  const pusher = React.useContext(PusherContext);

  useEffect(() => {
    const connection = pusher.subscribe(channel);
    connection.bind(event, onUpdate);
    pusher.connection.bind('error', function(error) {
      console.error('connection error', error)
    });
    return () => {
      connection.unbind(event, onUpdate);
    };
  }, [pusher, channel, event, onUpdate]);

  return null;
};

export default Pusher;

export { PusherProvider, usePusher, Pusher };
