import React from "react";
import FieldError from "./FieldError";
import uniqueString from "unique-string";

/* 
Textarea styled with bootstrap
Shows error below item
*/

const Textarea = ({
  name,
  label,
  error,
  rows,
  valid,
  lang,
  showLabel,
  ...rest
}) => {
  return (
    <div className="form-group">
      {showLabel && <label htmlFor={name}>{label}</label>}
      <textarea
        {...rest}
        name={name}
        id={uniqueString()}
        placeholder={label}
        className={`form-control ${error && "is-invalid"} ${
          valid && "is-valid"
        }`}
        rows={rows}
      />
      <FieldError name={name} error={error} />
    </div>
  );
};

export default Textarea;
