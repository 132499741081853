import React from "react";
import { Link } from "react-router-dom";
import { storeUpdater, useDispatch } from "../context/StoreProvider";
import { useUser } from "../hooks/userHooks";
import Login from "./modal/login/Login";

const userLinks = [
  {
    url: "/",
    label: "Home",
  },
];

const NotFound = () => {
  const user = useUser();
  const dispatch = useDispatch();
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");

  const linkClasses = "d-block text-center my-2";

  return (
    <div className="container my-5">
      <div className="row justify-content-center text-center">
        <div className="col">
          <h3>Page Not Found</h3>
          <p>Looks like the page you are looking for is missing.</p>
          <p>Here are some helpful links instead:</p>
        </div>
      </div>

      {user && (
        <>
          {userLinks.map((link, index) => (
            <Link key={index} className={linkClasses} to={link.url}>
              {link.label}
            </Link>
          ))}
        </>
      )}
      {!user && (
        <div className="mb-4">
          <Link className={linkClasses} to="/">
            Home
          </Link>

          <button
            className={`btn btn-link d-block mx-auto`}
            onClick={() => openModal(<Login />)}
          >
            Log In
          </button>

          {/* <button
            className={`btn btn-link d-block mx-auto`}
            onClick={() => openModal("signUp")}
          >
            Sign Up
          </button> */}
        </div>
      )}
    </div>
  );
};

export default NotFound;
