import React from "react";
import FieldError from "./FieldError";
import uniqueString from "unique-string";

/* 
Input field styled with bootstrap.
Defaults as text field, but can be changed.
*/

const Input = ({
  name,
  label,
  error,
  valid,
  prependIcon,
  lang,
  extraStyles = "",
  append,
  showLabel,
  ...rest
}) => {
  return (
    <div className={`form-group ${extraStyles}`}>
      {showLabel && <label htmlFor={name}>{label}</label>}
      <div className="input-group">
        <input
          {...rest}
          name={name}
          id={uniqueString()}
          autoComplete={extraStyles === "hidden" ? "off" : "on"}
          placeholder={label}
          className={`form-control ${error && "is-invalid"} ${
            valid && "is-valid"
          }`}
        />
        <FieldError name={name} error={error} />
        {append}
      </div>
    </div>
  );
};

export default Input;
