import React from "react";
import { useQuery } from "react-query";
import { getMaps } from "../services/mapService";

export const useMapTemplates = () => {
  const { data: allMaps } = useQuery("maps", getMaps);
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    if (!allMaps) return;
    setFiltered(allMaps);
  }, [allMaps]);

  return [filtered, setFiltered, allMaps];
};
