import React, { useState } from "react";
import Joi from "joi-browser";
import useForm from "../../common/hooksForm/useForm";
import { checkEmail } from "../../../services/userService";
import Flash, { useFlash } from "../../common/Flash";
import { sendPasswordReset } from "../../../services/emailService";
import { useGeneralMutation } from "../../../hooks/useGeneralMutation";

const ForgotPasswordForm = ({ setEmail }) => {
  const [initialValues] = useState({});
  const [flash, setFlash] = useFlash();

  const doCheckEmail = useGeneralMutation(checkEmail);
  const doSendPassWordReset = useGeneralMutation(sendPasswordReset);

  const handleSubmit = async (values) => {
    try {
      let data = await doCheckEmail(values.email);
      await doSendPassWordReset({ email: values.email, code: data.code });
      setEmail(values.email);
    } catch (ex) {
      setFlash(ex.response.data, "danger");
      return true;
    }
  };

  const form = useForm(handleSubmit, schema, initialValues);

  return (
    <form className="needs-validation" onSubmit={form.handleSubmit}>
      <Flash flash={flash} />
      {form.renderInput({ name: "email", label: "Email" })}
      {form.renderRecaptcha({ name: "recaptcha" })}
      {form.renderButton({
        name: "recoverPassword",
        label: "Recover Password",
        className: "btn-block",
      })}
    </form>
  );
};

export default ForgotPasswordForm;

const schema = {
  email: Joi.string().required().email(),
  recaptcha: Joi.string().required(),
};
