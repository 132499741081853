import React from "react";

const CatScore = ({ score, name, color }) => {
  return (
    <div className={` bg-${color} my-1 px-2 pt-2 pb-1`}>
      <h5 className="text-white">
        {name} <small className="ml-3">{score}%</small>
      </h5>
    </div>
  );
};

export default CatScore;
