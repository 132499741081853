//dependencies
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

//my modules
import NotFound from "./components/notFound";
import Logout from "./components/logout";
import Admin from "./components/adminPage/AdminPage";

//Protected Route
import ProtectedRoute from "./components/common/protectedRoute";

//css
import CompanySummary from "./components/companyManagement/CompanySummary";
import MapsPage from "./components/mapTemplateManagement/MapsPage";
import EditMap from "./components/mapTemplateManagement/EditMap";
import MapPage from "./components/companyManagement/MapPage";
import Login from "./components/modal/login/Login";
import LoginWithJwt from "./components/LoginWithJwt";
import UsersTable from "./components/adminPage/UsersTable";
import { isAdmin } from "./utils/functions/userFunctions";
import { useUser } from "./hooks/userHooks";
import AccountSettings from "./components/accountSettings/AccountSettings";
import CriticalPath from "./components/criticalPath/CriticalPath";

const Main = () => {
  const user = useUser("permissions");
  return (
    <main>
      <Switch>
        <ProtectedRoute
          path="/admin/maps/edit/:mapId"
          component={EditMap}
          requiredRole={["admin"]}
        />
        <ProtectedRoute
          path="/admin/maps"
          component={MapsPage}
          requiredRole={["admin"]}
        />
        <ProtectedRoute
          path="/admin/users"
          component={UsersTable}
          requiredRole={["admin"]}
        />
        <ProtectedRoute
          path="/admin"
          component={Admin}
          requiredRole={["admin"]}
        />
        <ProtectedRoute
          path="/company/:companyId"
          component={CompanySummary}
          requiredRole={["admin"]}
        />
        <ProtectedRoute path="/map/:companyId/:mapId" component={MapPage} />
        <ProtectedRoute
          path="/critical-path/:companyId/:mapId"
          component={CriticalPath}
        />
        <ProtectedRoute path="/account-settings" component={AccountSettings} />
        <ProtectedRoute path="/logout" component={Logout} needActive={false} />
        <Route path="/login/:jwt" component={LoginWithJwt} />
        <Route path="/not-found" component={NotFound} />
        <Route
          path="/"
          exact
          component={user && isAdmin(user) ? Admin : Login}
        />
        <Redirect to="/not-found" />
      </Switch>
    </main>
  );
};

export default Main;
