const R = require("ramda");
const dt = require("date-and-time");

//DATE MANIPULATION
export const dateToShortStr = (dateObj) => dt.format(dateObj, "YYYY-MM-DD");

export const strToDate = (str) =>
  new Date(
    str.substring(0, 4),
    parseInt(str.substring(5, 7)) - 1,
    str.substring(8, 10)
  );
export const dateToLocal = (lang = "en") => (dateObj) => {
  return dateObj.toLocaleDateString(lang, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const strToLocal = (str) => (lang = "en") => {
  return R.compose(dateToLocal(lang), strToDate)(str);
};

export const strToShortStr = (str) => str.substring(0, 10);

export const equalDates = (d1) => (d2) =>
  d1.setHours(0, 0, 0, 0) === d2.setHours(0, 0, 0, 0);

export const isToday = (date) => equalDates(new Date())(date);

export const subtractDates = (dateObj1) => (dateObj2) =>
  Math.round((dateObj1 - dateObj2) / 1000 / 60 / 60 / 24);

export const subStrDate = (dateStr1) => (dateStr2) =>
  subtractDates(new Date(dateStr1))(new Date(dateStr2));

export const daysPast = subtractDates(new Date());
export const daysUntil = (dateObj) => -1 * daysPast(dateObj);

export const thisSunday = (date) =>
  new Date(date.setDate(date.getDate() - (date.getDay() - 1) - 1));

export const onSchedule = (oldWeekStarting) =>
  strToShortStr(oldWeekStarting) === dateToShortStr(thisSunday(new Date()));

export const dateSort = (list) => (key) => {
  return R.sortBy(R.compose(strToDate, R.prop(key)))(list);
};

export const isExpired = (date) =>
  new Date(date).getTime() < new Date().getTime();
