import React from "react";

const Radio = ({ name, label, options, error, value, lang, ...rest }) => {
  return (
    <>
      <p className="mb-0">{label}</p>
      <div className="form-check mb-2">
        <div className="ml-1">
          {options.map((option) => (
            <div key={option._id}>
              <input
                type="radio"
                name={name}
                id={option.name}
                value={option._id}
                className="form-check-input"
                checked={parseInt(value) === option._id}
                {...rest}
              />
              <label htmlFor={option.name} className={`form-check-label`}>
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Radio;
