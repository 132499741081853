import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "../../context/StoreProvider";
import { useUser } from "../../hooks/userHooks";
import Login from "../modal/login/Login";
import MemberTopBarLinks from "./MemberTopBarLinks";

const TopBarLinks = () => {
  const user = useUser("");
  const dispatch = useDispatch();
  const toggleModal = () => {
    dispatch({ type: "OPEN_MODAL", payload: <Login /> });
  };

  return (
    <ul className="list-inline ml-2 mr-3 mb-0">
      {/* <li className="list-inline-item ">
        <Link className="text-muted text-decoration-none" to="/contact-us">
          Contact Us
        </Link>
      </li> */}
      <li
        className="list-inline-item text-muted "
        style={{ cursor: "pointer" }}
      >
        {user ? (
          <MemberTopBarLinks />
        ) : (
          <div className="list-inline-item text-muted" onClick={toggleModal}>
            Log In
          </div>
        )}
      </li>
    </ul>
  );
};

export default withRouter(TopBarLinks);
