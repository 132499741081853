import React from "react";
import FieldError from "./FieldError";

/* 
Input field styled with bootstrap.
Defaults as text field, but can be changed.
*/

const Checkbox = ({
  name,
  label,
  error,
  valid,
  prependIcon,
  extraStyles = "",
  ...rest
}) => {
  return (
    <div className="form-group">
      <input
        {...rest}
        type="checkbox"
        name={name}
        id={name}
        className={`form-check-input ${error && "is-invalid"} ${
          valid && "is-valid"
        } ${extraStyles}`}
      />
      <label className="form-check-label" for="exampleCheck1">
        {label}
      </label>
      <FieldError name={name} error={error} />
    </div>
  );
};

export default Checkbox;
