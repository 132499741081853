import Markdown from "markdown-to-jsx";
import React from "react";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import FieldPopUp from "../companyMapManagement/FieldPopUp";
import ScoreSelector from "../companyMapManagement/ScoreSelector";
import VideoLink from "../companyMapManagement/VideoLink";

const ActionBlock = ({ field, map, company, setVideo }) => {
  const dispatch = useDispatch();
  const actionSteps = field.blocks.find((b) => b.isActionStep);

  const popUp = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(
      <FieldPopUp
        fieldId={field._id}
        mapId={map._id}
        catId={field.catId}
        companyId={company._id}
      />
    );

  return (
    <div
      className="p-4 my-3 border border-1 d-block mx-auto"
      style={{ maxWidth: "900px" }}
    >
      <h3 className="mb-4">
        {field.catName} |{" "}
        <strong
          style={{
            cursor: "pointer",
          }}
          onClick={field.blocks.length ? popUp : () => {}}
        >
          {field.name}
        </strong>{" "}
        <ScoreSelector
          field={field}
          catId={field.catId}
          map={map}
          company={company}
        />
      </h3>
      {actionSteps && (
        <Markdown
          options={{
            overrides: {
              Video: {
                component: VideoLink(setVideo),
              },
            },
          }}
        >
          {actionSteps.content}
        </Markdown>
      )}
    </div>
  );
};

export default ActionBlock;
