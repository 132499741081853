import React, { useEffect } from "react";
import { withRouter } from "react-router";
import jwtDecode from "jwt-decode";
import { loginWithJwt } from "../services/authService";
import Loader from "./common/Loader";

const LoginWithJwt = ({ match, history }) => {
  useEffect(() => {
    loginWithJwt(match.params.jwt);
    const token = jwtDecode(match.params.jwt);
    window.location = `/map/${token.company._id}/${token.map_id}`;
  }, [match.params.jwt, history]);

  return <Loader />;
};

export default withRouter(LoginWithJwt);

//https://tinyurl.com/yxtpwdkh

//https://tinyurl.com/yykq7yrm
