import React from "react";
import { NavLink } from "react-router-dom";

const NavListItem = ({ link }) => {
  return (
    <li className="nav-item">
      <NavLink className="nav-link" to={link.url}>
        {link.label}
      </NavLink>
    </li>
  );
};

export default NavListItem;
