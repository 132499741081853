export const calcCatScore = (cat) => {
  return Math.round(
    (cat.fields.reduce(reduceCatScore, 0) /
      cat.fields.reduce(reduceCatWeight, 0)) *
      100
  );
};

export const reduceCatScore = (prev, next) =>
  prev + (next.score ? next.score : 0);
export const reduceCatWeight = (prev, next) => prev + next.scoringWeight;

export const calcColor = (value) =>
  value <= 29 ? "danger" : value <= 59 ? "warning" : "success";

export const calcTotalScore = (map) => {
  return Math.round(
    (map.categories.reduce(
      (prev, next) => prev + next.fields.reduce(reduceCatScore, 0),
      0
    ) /
      map.categories.reduce(
        (prev, next) => prev + next.fields.reduce(reduceCatWeight, 0),
        0
      )) *
      100
  );
};
