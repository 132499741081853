import React from "react";

import TopBarLinks from "./TopBarLinks";

const TopBar = () => {
  return (
    <div id="top-bar" className=" py-1">
      <div className="d-flex align-items-center">
        {/* <SocialLinks /> */}
        <div className="d-inline-block ml-auto" />
        <TopBarLinks />
      </div>
    </div>
  );
};

export default TopBar;
