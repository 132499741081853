import React from "react";
import { withRouter } from "react-router";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { useCompany, useMap } from "../../hooks/companyHooks";
import FieldPopUp from "../companyMapManagement/FieldPopUp";
import ScoreSelector from "../companyMapManagement/ScoreSelector";
import FieldForm from "./FieldForm";

const FieldDisplay = ({ cat, edit, index, mapId, match, fieldId }) => {
  const company = useCompany(match.params.companyId);
  const map = useMap(company, mapId);
  const category = map ? map.categories.find((c) => c._id === cat._id) : cat;
  const field = category.fields.find((f) => f._id === fieldId);

  const dispatch = useDispatch();
  const editField = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(
      <FieldForm field={field} mapId={mapId} catId={cat._id} />
    );

  const popUp = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(
      <FieldPopUp
        fieldId={fieldId}
        mapId={mapId}
        catId={cat._id}
        companyId={company._id}
      />
    );

  return (
    <div
      className={`list-group-item bg-light py-1 mt-1 text-center`}
      style={{ height: "100px" }}
    >
      {!edit && (
        <ScoreSelector
          field={field}
          catId={cat._id}
          map={map}
          company={company}
        />
      )}
      <div
        onClick={
          edit
            ? editField
            : field.blocks.length && field.name.trim()
            ? popUp
            : () => {}
        }
        style={{ minWidth: "20px", minHeight: "30px" }}
      >
        <p
          className={
            "font-weight-bold"
            // `${index < 2 ? "font-weight-bold" : ""}`
          }
          style={{
            cursor: "pointer",
          }}
          // onClick={edit ? editField : field.blocks.length ? popUp : () => {}}
        >
          {field.name}
        </p>
        {field.summary && <p style={{ fontSize: "12px" }}>{field.summary}</p>}
      </div>
    </div>
  );
};

export default withRouter(FieldDisplay);

export const color = (field) =>
  field.score ? (field.score > 0 ? "success" : "danger") : "warning";
