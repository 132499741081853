import React from "react";
import Modal from "react-modal";
import Close from "./Close";
import { useDispatch, useStore } from "../../context/StoreProvider";
import { storeUpdater } from "../../context/StoreProvider";

const CustomModal = ({ children }) => {
  const { modal } = useStore();
  const dispatch = useDispatch();
  const closeModal = storeUpdater(dispatch)("CLOSE_MODAL");

  return (
    <Modal
      isOpen={modal.open}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
      ariaHideApp={false}
      overlayClassName="modal-overlay"
    >
      <div className="text-center">
        <Close handleClick={closeModal} />
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "7px",
    boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.5)",
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "visible",
  },
};
