import React from "react";
import { useDispatch } from "../../../context/StoreProvider";
import SignUpForm from "./SignUpForm";
import { storeUpdater } from "../../../context/StoreProvider";
import Login from "../login/Login";

const SignUp = () => {
  const dispatch = useDispatch();
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");

  return (
    <div>
      <div className="pt-2 px-5">
        <h2 className="h4">Welcome!</h2>
        <p className="text-muted">Fill out this form to get started.</p>
        <SignUpForm />
        <p className="my-2 text-muted">
          Already have an account?
          <span
            className="text-primary btn-link"
            onClick={() => openModal(<Login />)}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
