import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Select from "./Select";
import Input from "./Input";
import Textarea from "./Textarea";
import Error from "./Error";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Recaptcha from "./Recaptcha";

/* 
Form function used to create forms easily
Has render functions for components
Accepts submission and validation functions and initial values
Initial values should be controlled by state in use component
*/

const useForm = (doSubmit, schema, initialValues, lang) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  //preloads initial values into values
  //This is done with useEffect incase initial values are coming
  //from the database and aren't shown on first render.
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    let errors = validate();
    if (errors) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }
    let res = await doSubmit(values);
    res && setIsSubmitting(false);
  };

  //validates entire form
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(values, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    //console.log(errors);
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const itemSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, itemSchema);
    if (error) {
      setErrors({ [name]: error.details[0].message, ...errors });
      const { [name]: targetValid, ...rest } = valid;
      setValid(rest);
    } else {
      const { [name]: targetError, ...rest } = errors;
      setErrors(rest);
      setValid({ [name]: true, ...valid });
    }
  };

  const handleBlur = (target) => {
    validateProperty(target);
  };

  //Updates form changes in values
  const handleChange = (event) => {
    const { target } = event;
    if (errors[target.name]) validateProperty(target);
    setValues({ ...values, [target.name]: target.value });
  };

  const handleCaptcha = (value) => {
    if (errors.recaptcha) validateProperty({ name: "recaptcha", value });
    setValues({ ...values, recaptcha: value });
  };

  //General use button that shows processing and disabled when submitting
  const renderButton = ({ label, className, disabled }) => {
    return (
      <button
        type="submit"
        className={"btn btn-primary " + className}
        disabled={isSubmitting || (disabled ? true : false)}
      >
        {!isSubmitting && label}
        {isSubmitting && (
          <>
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            />
            Processing...
          </>
        )}
      </button>
    );
  };

  const renderRecaptcha = ({ name = "recaptcha" }) => {
    return (
      <Recaptcha
        name={name}
        value={values.name || ""}
        onBlur={() => handleBlur({ name, value: values[name] })}
        onChange={handleCaptcha}
        //error={errors[name]}
        //valid={valid[name]}
      />
    );
  };

  //Drop down list
  const renderSelect = ({ name, label, options, append, optionGroups }) => {
    return (
      <Select
        name={name}
        value={values[name]}
        onBlur={() => handleBlur({ name, value: values[name] })}
        label={label}
        options={options}
        onChange={handleChange}
        error={errors[name]}
        valid={valid[name]}
        lang={lang}
        append={append}
        optionGroups={optionGroups}
      />
    );
  };

  //Radio Buttons
  const renderRadio = ({ name, label, options }) => {
    return (
      <Radio
        name={name}
        value={values[name]}
        onBlur={() => handleBlur({ name, value: values[name] })}
        label={label}
        options={options}
        onChange={handleChange}
        error={errors[name]}
        lang={lang}
      />
    );
  };

  //Generic Input element
  const renderInput = ({
    name,
    label,
    type = "text",
    extraStyles = "",
    prependIcon = "",
    append,
    showLabel,
  }) => {
    return (
      <Input
        name={name}
        value={values[name] || ""}
        prependIcon={prependIcon}
        label={label}
        onBlur={() => handleBlur({ name, value: values[name] })}
        onChange={handleChange}
        error={errors[name]}
        type={type}
        extraStyles={extraStyles}
        valid={valid[name]}
        lang={lang}
        append={append}
        showLabel={showLabel}
      />
    );
  };

  const renderCheckbox = ({ name, label, extraStyles = "" }) => {
    return (
      <Checkbox
        name={name}
        value={values[name] || ""}
        label={label}
        onBlur={() => handleBlur({ name, value: values[name] })}
        onChange={handleChange}
        error={errors[name]}
        extraStyles={extraStyles}
        valid={valid[name]}
        lang={lang}
      />
    );
  };

  //text area element. Accepts row heigh as argument
  const renderTextarea = ({ name, rows, label, showLabel }) => {
    return (
      <Textarea
        rows={rows}
        name={name}
        value={values[name] || ""}
        label={label}
        onBlur={() => handleBlur({ name, value: values[name] })}
        onChange={handleChange}
        error={errors[name]}
        valid={valid[name]}
        lang={lang}
        showLabel={showLabel}
      />
    );
  };

  //Error alert for form using bootstrap styling
  const renderError = (error) => {
    return <Error error={error} />;
  };

  return {
    handleChange,
    handleSubmit,
    values,
    renderInput,
    renderSelect,
    renderButton,
    renderTextarea,
    renderError,
    renderRadio,
    renderCheckbox,
    renderRecaptcha,
    errors,
    isSubmitting,
    validate,
    setErrors,
    setIsSubmitting,
  };
};

export default useForm;
