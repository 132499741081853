import * as React from "react";

/* 
Error alert styled with bootstrap
Does not display unless error is present.
*/

const Error = ({ error }) => {
  let content = <div />;
  if (error)
    content = (
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {error}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  return content;
};

export default Error;
