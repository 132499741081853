import React from "react";

const TotalScore = ({ score, color }) => {
  return (
    <div className="row my-3 px-3">
      <div
        className={`col-12 text-center py-1 `}
        // style={{ backgroundColor: "#000000" }}
      >
        <h4 className=" py-1">
          Total Score: {score}%{" "}
          <span className={`btn bg-${color} btn-circle ml-3`} />
        </h4>
      </div>
    </div>
  );
};

export default TotalScore;
