import React from "react";
import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <ReactLoading
      className="mx-auto my-5 d-block h-100"
      type="spokes"
      color="#007bff"
      height={"10vh"}
      width={"10vw"}
    />
  );
};

export default Loader;
