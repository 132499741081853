import React from "react";
import { withRouter } from "react-router";
import { getMap } from "../../services/mapService";
import Loader from "../common/Loader";
import MapDisplay from "../mapTemplateManagement/MapDisplay";
import { useQuery } from "react-query";

const EditMap = ({ match }) => {
  const { data: map } = useQuery(["map", match.params.mapId], getMap);

  if (!map) return <Loader />;
  return (
    <div className="container">
      {/* <div className="mx-auto" style={{ maxWidth: 1400 }}> */}
      <h2>Edit Deal</h2>
      <MapDisplay edit={true} map={map} />
    </div>
  );
};

export default withRouter(EditMap);
