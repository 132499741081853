import React from "react";
import { useQuery } from "react-query";
import {
  getCompanies,
  getCompany,
  updateCompany,
} from "../services/companyService";
import { useGeneralMutation } from "./useGeneralMutation";

export const useCompany = (companyId) => {
  const { data: company } = useQuery(["company", companyId], getCompany, {
    enabled: companyId,
  });
  return company;
};

export const useCompanyMaps = (companyId) => {
  const { data: company } = useQuery(["company", companyId], getCompany, {
    enabled: companyId,
  });
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    if (!company) return;
    setFiltered(company.maps);
  }, [company]);

  return [filtered, setFiltered, company.maps, company];
};

export const useUpdateCompany = (companyId, extraQueryArr) => {
  const mutator = useGeneralMutation(updateCompany, extraQueryArr);

  return (updates) => mutator({ companyId, updates });
};

export const useMap = (company, mapId) => {
  if (!company) return null;
  return company.maps.find((m) => m._id === mapId);
};

export const useCompanies = () => {
  const { data: allCompanies } = useQuery("companies", getCompanies);
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    if (!allCompanies) return;
    setFiltered(allCompanies);
  }, [allCompanies]);

  return [filtered, setFiltered, allCompanies];
};
